import React from "react";
import ReactDOM from "react-dom";

import "normalize.css/normalize";
import "./index.scss";
import logo from "./img/itsellesi_logo.png";
import bgimg from "./img/tausta.jpg";

const App = () => {
  return (
    <div className="page-container">
      <div className="top-bar">
        <div className="separation" />
        <div>
          <img className="logo" src={logo} alt="Itsellesi" />
        </div>
      </div>
      <div className="main">
        <div className="img-slogan-container">
          <img src={bgimg} alt="Itsellesi" />
          <div className="slogan">Oppimisen kokemus</div>
        </div>
        <div className="separation" />
        <div className="separation" />
        <div className="separation" />
      </div>
      <div className="footer">
        <div className="footer-block">
          <img className="logo" src={logo} alt="Itsellesi" />
        </div>
        <div className="footer-block">
          <div>Ota yhteyttä:</div>
          <div>Jari Mustonen</div>
          <div>jari@itsellesi.fi</div>
          <div>+358 44 069 7874</div>
        </div>
        <div className="footer-block">
          <div>Itsellesi Oy</div>
          <div>Y-tunnus: 3118873-9</div>
          <div>Harjunlahdentie 61</div>
          <div>52510 Hietanen</div>
        </div>
      </div>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
